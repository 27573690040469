import React from 'react';
import Button from '@material-ui/core/Button';
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from 'react-responsive-carousel';

import './Sales.scss';

function Sales() {
    return (
        <div className="sales">
            <div className="top-banner">
                <div className="content-wrapper">
                    <div className="sales__miniTitle">CodeTrack for Organizations</div>
                    <div className="sales__title">Guide and track developers as they pursue their first job</div>
                    <div className="sales__subtitle">
                        A powerful student or candidate tracking system, guiding job seekers
                        from day one of training to a full-time job as a software developer. Plans start at $100 per month.
                    </div>
                    <div className="sales__callToAction"></div>
                    <div className="sales__callToAction">
                        <Button
                            variant="contained"
                            color="primary"

                        >
                            Request a Demo
                        </Button>
                    </div>
                </div>
            </div>

            <div className="organization-section">
                <div className="conatiner">
                    <div className="section-tile">For your Organization</div>
                    <div className="organization-list">
                        <div className="item">
                            <div className="image">
                                <img src="/images/Graphic-8.png" alt="Graphic-8" className="" />
                            </div>
                            <h3>Branded Portal</h3>
                            <p>Let your learners collaborate and compete through a branded portal with only your learners.</p>
                        </div>
                        <div className="item">
                            <div className="image">
                                <img src="/images/Graphic-7.png" alt="Graphic-7" className="" />
                            </div>
                            <h3>Automated Reporting</h3>
                            <p>Get both real-time and weekly reports through your web portal or as CSV data to integrate in other tools.</p>
                        </div>
                        <div className="item">
                            <div className="image">
                                <img src="/images/Graphic-6.png" alt="Graphic-6" className="" />
                            </div>
                            <h3>Inspire Competition</h3>
                            <p>Hold formal or informal competitions around algorithm completion, portfolio work or job applications.</p>
                        </div>
                        <div className="item">
                            <div className="image">
                                <img src="/images/Graphic-5.png" alt="Graphic-5" className="" />
                            </div>
                            <h3>Promote Transparency</h3>
                            <p>Help students understand how they compare to their classmates and how candidates are chosen for job opportunities.</p>
                        </div>
                        <div className="item">
                            <div className="image">
                                <img src="/images/Graphic-4.png" alt="Graphic-4" className="" />
                            </div>
                            <h3>Improve Code Reviews</h3>
                            <p>Instructors can see immediately when code has been pushed and review it. No more checking individual Github accounts.</p>
                        </div>
                        <div className="item">
                            <div className="image">
                                <img src="/images/Graphic-3.png" alt="Graphic-3" className="" />
                            </div>
                            <h3>Amazing Integrations</h3>
                            <p>CodeTrack is integrated with many well-loved tools like Github, LeetCode, Codewars and Huntr. Set up a demo to see how it can work for you.</p>
                        </div>
                        <div className="item">
                            <div className="image">
                                <img src="/images/Graphic-2.png" alt="Graphic-2" className="" />
                            </div>
                            <h3>CodeTrack Drives Hiring Success</h3>
                            <p>The data is in! CodeTrack users with higher scores are much more likely to turn an interview into an offer.</p>
                        </div>
                        <div className="item">
                            <div className="image">
                                <img src="/images/Graphic-1.png" alt="Graphic-1" className="" />
                            </div>
                            <h3>Video Lessons</h3>
                            <p>Keep learners moving forward in their development with video lessons teaching algorithms and other computer science concepts.</p>
                        </div>
                        <div className="item">
                            <div className="image">
                                <img src="/images/Graphic.png" alt="Graphic" className="" />
                            </div>
                            <h3>Everyone Loves It!</h3>
                            <p>CodeTrack is the rare product that is loved by learners, instructors and administrators alike!</p>
                        </div>
                    </div>
                </div>
            </div>
            {/* <div className="sales__salesPoints">
                <div className="sales__title">For your organization</div>
                <div className="sales__salesCards">
                    <div className="salesCard">
                        <div className="salesImage" style={{ "background-image": "url('/sales_images/huntr_advisor_feature_branded.png')" }}></div>
                        <h3>Branded Portal</h3>
                        <p>Let your learners collaborate and compete through a branded portal with only your learners.</p>
                    </div>
                    <div className="salesCard">
                        <div className="salesImage" style={{ "background-image": "url('/sales_images/graph.png')" }}></div>
                        <h3>Automated Reporting</h3>
                        <p>Get both real-time and weekly reports through your web portal or as CSV data to integrate in other tools.</p>
                    </div>
                    
                    <div className="salesCard">
                        <div className="salesImage" style={{ "background-image": "url('/sales_images/student_card.png')" }}></div>
                        <h3>Inspire Competition</h3>
                        <p>Hold formal or informal competitions around algorithm completion, portfolio work or job applications.</p>
                    </div>
                    <div className="salesCard">
                        <div className="salesImage" style={{ "background-image": "url('/sales_images/graph.png')" }}></div>
                        <h3>Promote Transparency</h3>
                        <p>Help students understand how they compare to their classmates and how candidates are chosen for job opportunities.</p>
                    </div>
                    <div className="salesCard">
                        <div className="salesImage" style={{ "background-image": "url('/sales_images/commits_tracker.png')" }}></div>
                        <h3>Improve Code Reviews</h3>
                        <p>Instructors can see immediately when code has been pushed and review it. No more checking individual Github accounts.</p>
                    </div>
                    <div className="salesCard">
                        <div className="salesImage" style={{ "background-image": "url('/sales_images/integrations.png')" }}></div>
                        <h3>Amazing Integrations</h3>
                        <p>CodeTrack is integrated with many well-loved tools like Github, LeetCode, Codewars and Huntr. Set up a demo to see how it can work for you.</p>
                    </div>
                    <div className="salesCard">
                        <div className="salesImage" style={{ "background-image": "url('/sales_images/graph.png')" }}></div>
                        <h3>CodeTrack Drives Hiring Success</h3>
                        <p>The data is in! CodeTrack users with higher scores are much more likely to turn an interview into an offer.</p>
                    </div>
                    <div className="salesCard">
                        <div className="salesImage" style={{ "background-image": "url('/sales_images/video_lessons.png')" }}></div>
                        <h3>Video Lessons</h3>
                        <p>Keep learners moving forward in their development with video lessons teaching algorithms and other computer science concepts.</p>
                    </div>
                    <div className="salesCard">
                        <div className="salesImage" style={{ "background-image": "url('/sales_images/graph.png')" }}></div>
                        <h3>Everyone Loves It!</h3>
                        <p>CodeTrack is the rare product that is loved by learners, instructors and administrators alike!</p>
                    </div>
                </div>
            </div> */}
            <div className="sales_testimonials">
                <div className="sales__title"> Testimonials</div>
                <div className="left dot-bg">
                    <img src="/images/dot.png" alt="" />
                </div>
                <div className="right dot-bg">
                    <img src="/images/dot.png" alt="" />
                </div>
                <div className='conatiner'>
                <Carousel>
                    <div>
                        <div className="card-info" style={{ marginTop: "15px" }}>
                            <div className="name">Jordan B.</div>
                            <div className="role">Engineer @ Uber</div>
                            <div className="content">CodeTrack has all the tools to help you dominate data structures and algorithms! It gave me the confidence to focus more on improving my behavioral skills!</div>
                        </div>
                    </div>
                    <div className="card-info" style={{ marginTop: "15px" }}>
                        <div className="name">Daniel N. </div>
                        <div className="role">Engineer @ Uber</div>
                        <div className="content">
                            <p>I'd like to shout out CodeTrack for starting a friendly coding competition among peers. It helped me improve my logic skills and solidify the fundamentals of JavaScript. The structure of CodeTrack, organized by category, allowed me to start from scratch and finish strong with data structures and algorithms.</p>
                            <p>I'm happy to say that the CodeTrack motivated me and helped me accumulate the necessary experience in order to successfully implement algorithms during interviews.</p>
                        </div>
                    </div>
                    <div className="card-info" style={{ marginTop: "15px" }}>
                        <div className="name">Obaid R. </div>
                        <div className="role">Engineer @ Uber</div>
                        <div className="content">
                            <p>CodeTrack was an invaluable tool for me. It provided a progressive route for me to practice the fundamentals and use them as building blocks to solve harder and harder problems. It also sharpened my ability with test-driven development.</p>
                            <p>It allowed me to compete in a fun and constructive way with my peers. We tried to beat each other's points on a weekly basis and as a result we collectively strengthened our coding skills.</p>
                            <p>It really helped me prepare for my interview both technically and psychologically. It definitely gets progressively harder though so start with the easy problems and work your way up!</p>
                        </div>
                    </div>
                </Carousel>
                </div>
            </div>
        </div>
    );
}

export default Sales;